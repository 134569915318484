import React, {useState, useEffect} from 'react';
import style from '../../assets/style/category/categoryCard.module.scss'
import useFetch from "../../hooks/useFetchPost";
import Alert from "../alert/Alert";
import { Link, useParams } from 'react-router-dom';

function ShopCard({categoryData, isFavorite}) {
    // const [send, setSend] = useState(false);
    // const [count, setCount] = useState(4);
    // const [showAlert, setShowAlert] = useState(false);
    // const [isFav, setIsFav] = useState(isFavorite);
    // const token = localStorage.getItem("muslim_comunity_token")
  const {id} = useParams();

    // let formData = new FormData();
    // formData.append('id', categoryData?.id);

    // const [Res] = useFetch('favorite/store', formData, send);

    // let favoriteIcon = isFav ? 'fas fa-star' : 'far fa-star';

    // useEffect(() =>
    // {
    //   console.log('id',categoryData.id);
    //   console.log('prop',isFavorite);
    //   console.log('state', isFav);
    // },[]);

    const handleShareClick = (e) => {
        const { protocol, host } = window.location;
        let targetPath = `/ShopProfile/${e}`;
        const sharedUrl = `${protocol}//${host}${targetPath}`;
        navigator.share({
          title: 'Shared title',
          text: 'Shared text',
          url: sharedUrl,
        })
          .then(() => console.log('Shared successfully'))
          .catch((error) => console.error('Sharing failed:', error));
      };


      // const addToFavorite = () => {
      //   if(token){
      //     console.log(categoryData?.id);
      //     console.log(isFav);
      //   setIsFav(!isFav);
      //   setSend(true);
      //   setTimeout(() => {
      //       setSend(false);
      //     }, 100);
      //   }else{
      //       setShowAlert(true);
      //   }
      // }

  return (
    <Link to={`/Shop-Profile/${categoryData.id}`} className={`col-lg-3 col-md-4 col-sm-6 ${style.mainCategoryCard}`}>
        <div className={`${style.categoryCardBody}`}>
            <img className={style.categoryImage} src={categoryData.image}  />

            <div className={`row ${style.categoryMainInfoBox}`}>
                <div className={`col-10 ${style.categoryInfo}`}>
                    <h4 className={style.shopTitle}>{categoryData.name}</h4>
                </div>
                <div className={`col-2 ${style.storeAction}`}>
                     {/* <i className="fas fa-share-square" onClick={()=> handleShareClick(categoryData.id)}></i> */}

                    {/* <i className={`${favoriteIcon} ${style.favIconColor}`} onClick={()=> addToFavorite()}></i> */}
                </div>

            </div> 

        </div>
    </Link>
  )
}

export default ShopCard