import React from 'react';
import Card from './CategoryCard';
import Pagination from '../common/Pagination';
import style from '../../assets/style/house/housingCard.module.scss';
import { useDispatch } from 'react-redux';
import { setCategoryId } from '../../redux/CategoryRedux';
import { setSubCategoryId } from '../../redux/CategoryRedux';

function HouseSection({limit, categoryData, previousPage, nextPage, total, setActiveIndex, activeIndex, page, setPage, scrollPagination,categoryState, setCategoryState, subFilter, mainFilter, setMainFilter, setSubFilter}) {
  const dispatch = useDispatch();

  // const removeFilter = (e) => {
  //   if(e === 'main'){
  //       setCategoryState({...categoryState, activeFilterTitle:'', mainId:'', activeSubFilterTitle:'', subId:''})
  //       dispatch(setCategoryId(''));
  //       dispatch(setSubCategoryId(''));
  //   }else if(e === 'sub'){
  //       setCategoryState({...categoryState, activeSubFilterTitle:'', subId:''})
  //       dispatch(setSubCategoryId(''));
  //   }
  // }

  return (
    <>
       {/* <div className={`row `}>
        {categoryState.activeFilterTitle && (
          <h3 className={style.filterResult}>{categoryState.activeFilterTitle} <i className="far fa-times-circle" onClick={()=> removeFilter('main')}></i></h3>
        )}
        {categoryState.activeSubFilterTitle && (
          <h3 className={style.filterResult}>{categoryState.activeSubFilterTitle} <i className="far fa-times-circle" onClick={()=> removeFilter('sub')}></i></h3>
        )}

    </div> */}
    
    <div className={`row `}>
        {categoryData?.map((item, index)=>
              <Card key={index} categoryData = {item} />
        )}
    </div>

            {limit < total && (
                <Pagination
                    totalPosts={total}
                    postsPerPage={limit}
                    setCurrentPage={setPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    currentPage={page}
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    scrollPagination={scrollPagination}
                />
            )
            }
    </>
  )
}

export default HouseSection