import React from 'react'
import style from '../../assets/style/shopProfile/shopProfileCover.module.css'

function ProfileImageCover({showStoreData}) {
  const profileImageCoverData = showStoreData?.banners[0]
  const firstBanner = profileImageCoverData?.image
  console.log(firstBanner);
  const isMp4File = firstBanner?.endsWith('.mp4')

  return (
    <div className={style.profileImageCoverDiv}>
      {isMp4File ? (
        <video src={firstBanner} autoPlay loop muted playsInline data-wf-ignore="true" data-object-fit="cover"  type="video/mp4" />
      ) : (
        <img src={firstBanner} alt="Profile Cover" />
      )}
    </div>
  )
}

export default ProfileImageCover
