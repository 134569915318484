import React from 'react'
import style from "../../assets/style/showJob/showJob.module.css";


function ShowJobImage({showJobData}) {
  return (
    <div className={style.showJobMainImage} >
        <img src={showJobData?.user_image}/>
    </div>
  )
}

export default ShowJobImage
