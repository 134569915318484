import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

function useFetch(url, formData, send) {
  const [Res, setRes] = useState([]);
  const [Err, setErr] = useState([]);
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("muslim_comunity_token");
  const baseUrl = token? `https://${process.env.REACT_APP_domain}/api/v1/${t('lang')}/client` : `https://${process.env.REACT_APP_domain}/api/v1/${t('lang')}`;

  useEffect(() => {
    if(send){
     try {
        fetch(`${baseUrl}/${url}`, {
            headers: { 'Authorization': `Bearer ${token}` , 'Accept': 'application/json' },
            method: 'POST',
            body: formData
          })
          .then(response => response.json())
          .then((data) => {setRes(data)
          // console.log(data);
          }
          )
      } 
      catch (error) {
        // console.log(error);
        setRes(error)
      }
    }
    }, [url, formData, send]);
 
    return [Res , setRes, setErr, Err];
}
  

export default useFetch;
