import { useState, useEffect } from "react";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function useAxios(url) {
  const [Data, setData] = useState([]);

  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("muslim_comunity_token");

  const baseUrl = token? `https://${process.env.REACT_APP_domain}/api/v1/${t('lang')}/client` : `https://${process.env.REACT_APP_domain}/api/v1/${t('lang')}`;

  useEffect(() => {
     try {
        axios
        .get(`${baseUrl}/${url}`, { headers: {"Authorization" : `Bearer ${token}`} })
        .then((response) => setData(response.data))
      } 
      catch (error) {
        console.log(error);
      }
    }, [url]);
 
    return [Data , setData];
}
  

export default useAxios;
