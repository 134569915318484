import React, { useEffect } from "react";
import "./App.css";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import NavBar from "./components/layout/NavBar";
import HomePage from "./pages/HomePage";
import Footer from "./components/layout/Footer";
import Page404 from "./pages/Page404";
import UserProfilePage from "./pages/UserProfilePage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import AboutUsPage from "./pages/AboutPage";
import ContactUsPage from "./pages/ContactUsPage";
import JobPage from "./pages/JobPage";
import MasjidPage from "./pages/MasjidPage";
import HousingPage from "./pages/HousingPage";
import BlogPage from "./pages/BlogPage";
import CategoryPage from "./pages/CategoryPage";
import ShopPage from "./pages/ShopPage";
import ShowBlogPage from "./pages/ShowBlogPage";
import ShowHousingPage from "./pages/ShowHousingPage";
import ShowJobPage from "./pages/ShowJobPage";
import PostJobPage from "./pages/PostJobPage";
import PostRentPage from "./pages/PostHousingPage";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import Terms_conditions from "./components/legal/Terms_conditions";
import ShopProfilePage from "./pages/ShopProfilePage";
import SearchResultPage from "./pages/SearchResultPage";
import SavedJobPage from "./pages/SavedJobPage";
import SavedStorePage from "./pages/SavedStorePage";
import SavedAccomodationPage from "./pages/SavedAccomodationPage";
import MyJobPage from "./pages/MyJobPage";
import MyHousingPage from "./pages/MyHousingPage";
import DeleteAccountPage from "./pages/DeleteAccountPage";
import OneSignal from 'react-onesignal';
import EulaPage from "./pages/EulaPage";


function App() {
  useEffect(() => 
  {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_KEY,
    });
  }, []);

  const { t, i18n } = useTranslation();

  const autherized = localStorage.getItem("muslim_comunity_token");

  const baseUrl = autherized
    ? `https://${process.env.REACT_APP_domain}/api/v1/${t("lang")}/client`
    : `https://${process.env.REACT_APP_domain}/api/v1/${t("lang")}`;

  return (
    <>
      <Router>
        <ScrollToTop />
        <NavBar />

        <Routes>
          <Route element={<PrivateRoutes />}> 
            <Route path="/Profile" element={<UserProfilePage baseUrl={baseUrl} />}  />
            <Route path="/saved-job" element={<SavedJobPage baseUrl={baseUrl} />}  />
            <Route path="/saved-store" element={<SavedStorePage baseUrl={baseUrl} />}  />
            <Route path="/saved-accomodation" element={<SavedAccomodationPage baseUrl={baseUrl}/>} />
            <Route path="/my-job" element={<MyJobPage baseUrl={baseUrl} />}  />
            <Route path="/my-housing" element={<MyHousingPage baseUrl={baseUrl}/>} />
            <Route path="/change-password" element={<ChangePasswordPage baseUrl={baseUrl} />}  />
            <Route path="/delete-account" element={<DeleteAccountPage baseUrl={baseUrl} />}  />
          </Route>

          <Route path="/" element={<HomePage baseUrl={baseUrl} />} />
          <Route path="/Login" element={<LoginPage baseUrl={baseUrl} />} />
          <Route
            path="/Register"
            element={<RegisterPage baseUrl={baseUrl} />}
          />
          <Route path="/About" element={<AboutUsPage baseUrl={baseUrl} />} />
          <Route
            path="/Contact"
            element={<ContactUsPage baseUrl={baseUrl} />}
          />
          
          <Route path="/Jobs" element={<JobPage baseUrl={baseUrl} />} />
          <Route path="/Masjid" element={<MasjidPage baseUrl={baseUrl} />} />
          <Route path="/Housing" element={<HousingPage baseUrl={baseUrl} />} />
          <Route path="/Blog" element={<BlogPage baseUrl={baseUrl} />} />
          <Route
            path="/Category"
            element={<CategoryPage baseUrl={baseUrl} />}
          />
          <Route
            path="/Shop"
            element={<ShopPage baseUrl={baseUrl} />}
          />
          <Route
            path="/Show-Blog/:id"
            element={<ShowBlogPage baseUrl={baseUrl} />}
          />
           <Route
            path="/Show-Housing/:id"
            element={<ShowHousingPage baseUrl={baseUrl} />}
          />
            <Route
            path="/Show-Job/:id"
            element={<ShowJobPage baseUrl={baseUrl} />}
          />
           <Route
            path="/Post-Housing"
            element={<PostRentPage baseUrl={baseUrl} />}
          />
           <Route
            path="/Post-Job"
            element={<PostJobPage baseUrl={baseUrl} />}
          />
            <Route
            path="/Shop-Profile/:id"
            element={<ShopProfilePage baseUrl={baseUrl} />}
          />
          <Route exact path="/Privacy-Policy" element={<PrivacyPolicy />} />
          <Route exact path="/Terms-conditions" element={<Terms_conditions />} />
          <Route exact path='/eula' element={<EulaPage />} />

          <Route exact path="*" element={<Page404 />} />
          <Route exact path="/search-result/:keyword/:type?" element={<SearchResultPage />} />
 
        </Routes>

        <Footer />
      </Router>
    </>

  );
}

export default App;
