import React from 'react'
import style from '../../assets/style/showHousing/showHousing.module.css'

function ShowHousingImage({showHousingData}) {
  return (
    <div className={style.showHousingImageDiv}>
        <img src={showHousingData?.image} />
    </div>
  )
}

export default ShowHousingImage