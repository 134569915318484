import React from "react";
import style from "../../assets/style/about/about.module.scss";

function AboutImage({aboutData}) {
  return (
    <div className={`col-lg-4 ${style.aboutImage}`}>
      <img src={aboutData?.main?.image}/>
    </div>
  );
}

export default AboutImage;
