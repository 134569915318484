import React from "react";
import style from "../../assets/style/showBlog/showBlog.module.css";

function ShowBlogParagraph({ showBlogData }) {
  const showBlogParagraphData = showBlogData?.paragraphs;
  return (
    <div className={style.showBlogParagraphContainer}>
      {showBlogParagraphData?.map((item, index) => (
        <div className={style.showBlogParagraphMain}>
          <h3>{item?.title}</h3>
          <p>
          {item?.description}
          </p>
          <div className={style.showBlogParagraphImage}>
            <img src={ item?.image} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ShowBlogParagraph;
