import React from 'react';
import style from '../../../assets/style/common/interested.module.scss'
import { Link } from 'react-router-dom';


function InterestedCard({cardData, type}) {

  let path;
  if(type === 'rent'){
    path = `/Show-Housing/${cardData.id}`

  }else if(type === 'job'){
    path = `/Show-Job/${cardData.id}`

  }else if(type === 'blog'){
    path = `/Show-Blog/${cardData.id}`

  }else if(type === 'store'){
    path = `/Shop-Profile/${cardData.id}`
  }

  return (
    <Link to={path} className={`col-lg-2 col-md-4 col-sm-6 ${style.interestedCard}`}>
    <img className={style.interestedImg} src={cardData.image} />
    <p className={style.interestedTitle}>{cardData.title}</p>
    <p className={style.interestedDescription}>{cardData.description}</p>
  </Link>
  )
}

export default InterestedCard