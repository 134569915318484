import React, { useState } from "react";
import style from "../../assets/style/layout/navbar.module.scss";
import { Link } from "react-router-dom";
import MenuDropDown from "../common/MenuDropDown";
import Button from "../common/Button";
import NavSearch from "../common/NavSearch";
import { useLocation } from "react-router-dom";
import btnStyle from "../../assets/style/common/button.module.scss";
import { Route, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function NavBar() {
  const [showNavbar, setShowNavbar] = useState(false);
  const languageIcon = <i className="fas fa-globe"></i>;
  const [navbar, setNavbar] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const homeNavbar = location.pathname === "/";
  const pathName = location.pathname.toLocaleLowerCase();
  const navigate = useNavigate();
  const initialState = {
    username: localStorage.getItem("muslim_comunity_userName")
      ? localStorage.getItem("muslim_comunity_userName")
      : "Guest",
    newField: null,
  };
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
      

  };

  const handleCloseModal = () => {
    setShowNavbar(false);

  }

  const changeBackground = () => {
    if (window.scrollY >= 1) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };
  const rtlDir = () => {
    // localStorage.setItem("lang", "ar");
    document.getElementById("root").style.direction = "rtl";
  };
  window.addEventListener("scroll", changeBackground);
  // console.log(pathName);
  const hideNavbar =
    location.pathname.toLocaleLowerCase() === "/privacy-policy" ||
    location.pathname.toLocaleLowerCase() === "/terms-conditions";

  return (
    <>
      {!hideNavbar && (
        <header
          className={`${style.headerContainer} ${
            navbar ? style.activeNav : ""
          } ${homeNavbar ? style.headerContainerHome : ""}`}
        >
          <div className={i18n.language === "ar" ? style.logoDivAr : ""}>
            <Link to="/">
              <img
                className={style.LogoImage}
                alt="Logo"
                src={
                  navbar
                    ? require("../../assets/images/Common/whiteLogo.png")
                    : require("../../assets/images/Common/Logo.png")
                }
              />
            </Link>
          </div>
          <nav className={style.navigationBarContainer}>
            <ul className={style.navigationBarUnorderedList}>
              <Link to="/">
                <li className={pathName === "/" ? style.activePath : ""}>
                  {t("Home")}{" "}
                </li>
              </Link>
              <Link to="/About">
                <li className={pathName === "/about" ? style.activePath : ""}>
                  About{" "}
                </li>
              </Link>
              <Link to="/Category">
                <li
                  className={pathName === "/category" ? style.activePath : ""}
                >
                  Categories{" "}
                </li>
              </Link>
              <li>
                <MenuDropDown
                  dropDownInfo="Services"
                  handleCloseModal = {handleCloseModal}
                  menuElements={[
                    { path: "/Jobs", title: "Jobs" },
                    { path: "/Housing", title: "Housing" },
                    { path: "/Masjid", title: "Masjids" },
                  ]}
                />
              </li>
              <Link to="/Contact">
                <li className={pathName === "/contact" ? style.activePath : ""}>
                  Contact{" "}
                </li>
              </Link>
              <Link to="/Blog">
                <li className={pathName === "/blog" ? style.activePath : ""}>
                  Blog{" "}
                </li>
              </Link>
            </ul>
          </nav>
          <div className={style.rightSubContainer}>
            <div className={style.navBarSearchMainDiv}>
              <NavSearch />
            </div>

            <div>
              {initialState && initialState.username === "Guest" ? (
                <Link to="/login">
                  <Button
                     handleCloseModal = {handleCloseModal}
                    btnInfo={
                      <div className={btnStyle.iconUserDiv}>
                        <i className={`far fa-user  ${style.userIcon}`}></i>
                        Login
                      </div>
                    }
                  />
                </Link>
              ) : (
                <MenuDropDown
                  dropDownInfo={
                    <div className={btnStyle.userloggedinBtn}>
                      <i className={`far fa-user  ${style.userIcon}`}></i>
                      {initialState.username}
                    </div>
                  }
                  handleCloseModal = {handleCloseModal}
                  menuElements={[
                    {
                      path: "/Profile",
                      
                      title: (
                        <div>
                          <i className="fas fa-user-circle">Profile</i>{" "}
                        </div>
                      ),
                    },
                    {
                      path: "/",
                      title: (
                        <div onClick={logout}>
                          <i className="fas fa-sign-out-alt"> Logout </i>{" "}
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </div>
            {/* <div>
              <MenuDropDown
                dropDownInfo={languageIcon}
                menuElements={[
                  { title: "AR", language: "ar" },
                  { title: "EN", language: "en" },
                  { title: "UR", language: "ur" },
                ]}
              />
            </div> */}
          </div>
          <div
            className={
              i18n.language === "en"
                ? style.searchMenuDivMobile
                : style.searchMenuDivMobileAr
            }
          >
            <div
              className={`${style.navBarSearchMainDiv} ${style.navMobileMargin}`}
            >
              <NavSearch />
            </div>
            <div className={`${style.burgerMenu}`} onClick={handleShowNavbar}>
              <i className={`fas fa-bars`}></i>
            </div>
          </div>
          {showNavbar && (
            <div className={style.showNavBarMainContainerMobile}>
              <div
                className={`${showNavbar ? style.showNavigationBarMobile : ""}`}
              >
                <div className={style.showNavBarMainMobile}>
                  <nav className={style.navigationBarContainerMobile}>
                    <ul className={style.navigationBarUnorderedList}>
                      <Link to="/" onClick={handleCloseModal}>
                        <li>Home </li>
                      </Link>
                      <Link to="/About" onClick={handleCloseModal}>
                        <li>About </li>
                      </Link>
                      <Link to="/Category" onClick={handleCloseModal}>
                        <li>Categories </li>
                      </Link>
                      <li>
                        <MenuDropDown
                          dropDownInfo="Services"
                          handleCloseModal = {handleCloseModal}
                          // onClick={handleCloseModal}
                          menuElements={[
                            { path: "/Jobs", title: "Jobs" },
                            { path: "/Housing", title: "Housing" },
                            { path: "/Masjid", title: "Masjids" },
                          ]}
                        />
                      </li>
                      <Link to="/Contact" onClick={handleCloseModal}>
                        <li>Contact </li>
                      </Link>
                      <Link to="/Blog" onClick={handleCloseModal}>
                        <li>Blog </li>
                      </Link>
                    </ul>
                  </nav>
                  <div className={style.rightSubContainerMobile}>
                    <div>
                      {initialState && initialState.username === "Guest" ? (
                        <Link to="/login">
                          <Button
                            handleCloseModal = {handleCloseModal}
                            btnInfo={
                              <div className={btnStyle.iconUserDiv}>
                                <i
                                  className={`far fa-user  ${style.userIcon}`}
                                ></i>
                                Login
                              </div>
                            }
                          />
                        </Link>
                      ) : (
                        <MenuDropDown
                        handleCloseModal = {handleCloseModal}

                          dropDownInfo={
                            <div className={btnStyle.userloggedinBtn}>
                              <i
                                className={`far fa-user  ${style.userIcon}`}
                              ></i>
                              {initialState.username}
                            </div>
                          }
                          menuElements={[
                            {
                              path: "/Profile",
                              title: (
                                <div>
                                  <i className="fas fa-user-circle">Profile</i>{" "}
                                </div>
                              ),
                            },
                            {
                              path: "/",
                              title: (
                                <div onClick={logout}>
                                  <i className="fas fa-sign-out-alt">
                                    {" "}
                                    Logout{" "}
                                  </i>{" "}
                                </div>
                              ),
                            },
                          ]}
                        />
                      )}
                    </div>

                    {/* <div className={style.navMobileMargin}>
                      <MenuDropDown
                        dropDownInfo={languageIcon}
                        menuElements={[
                          { title: "AR", language: "ar" },
                          { title: "EN", language: "en" },
                          { title: "UR", language: "ur" },
                        ]}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </header>
      )}
    </>
  );
}

export default NavBar;
