import React from 'react';
import style from '../../assets/style/authentication/login.module.scss'

function LeftLogin() {
  return (
    <div className={`${style.leftAuth} col-sm-12 col-md-6 col-lg-5`}>
        <img src={require('../../assets/images/login/loginLogo.png')} alt="Login Logo" />
    </div>
  ) 
}

export default LeftLogin