import React from 'react'
import MasjidImage from './MasjidImage';
import FindMasjidSection from '../../components/common/findMasjidSection/FindMasjidSection'
import MasjidList from './MasjidList';

function MasjidContainer({findMasjidData  , masjidKeyword , setMasjidKeyword }) {
  return (
    <div>
        <MasjidImage findMasjidData={findMasjidData} />
        <FindMasjidSection masjidKeyword={masjidKeyword} setMasjidKeyword={setMasjidKeyword} />
        <MasjidList findMasjidData={findMasjidData}/>
    </div>
  )
}

export default MasjidContainer

